import React from "react"

import { useTranslation } from "gatsby-plugin-react-i18next"
import { Footer } from "../../components/footer"
import { Header } from "../../components/header"
import { IkiBackground } from "../../components/svgs/ikiBackground"

import "./styles.css"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../../components/seo"

export default function About({ location }) {
  const { t } = useTranslation("about")

  return (
    <div className="about">
      <Seo />
      <div className="about-hero">
        <Header showBackground pathname={location.pathname} />
        <div className="ikiBackground">
          <IkiBackground />
        </div>
        <div className="pageTitle">
          <h1 className="title">{t("A propos")}</h1>
          <h4 className="bold">
            {t("IKI, l'art de vous recevoir pour créer un moment de bonheur")}
          </h4>
        </div>
      </div>
      <div className="ikigai">
        <StaticImage
          src="../../images/ikigai.webp"
          className="ikigai-image"
          objectFit="contain"
          placeholder="blurred"
          alt="ikigai"
        />
        <div className="ikigai-textBlock">
          <div className="centeredBlock">
            <h2>{t("Qu'est-ce que IKI ?")}</h2>
            <h3>
              {t(
                'Abréviation du mot japonais "Ikigai" qui signifie "raison d\'être", Cette philosophie vise à ce que chaque humain trouve sa place au sein de la société par le biais d’une profession qui lui corresponde parfaitement parce qu’elle se situe au carrefour de ce pour quoi il est doué, ce qui le passionne et ce dont la société à besoin.'
              )}
            </h3>
            <a
              href="https://en.wikipedia.org/wiki/Ikigai"
              target="_blank"
              rel="noreferrer"
            >
              <button>{t("Plus sur Ikigai")}</button>
            </a>
          </div>
        </div>
      </div>
      <StaticImage
        src="../../images/bar-far.webp"
        className="bar"
        placeholder="blurred"
        alt="bar from far away"
      />
      <div className="aWord">
        <h2>{t("Le mot du fondateur")}</h2>
        <h3>
          {t(
            '« Partager mon amour pour la Belgique et pour l’Asie au travers de la cuisine est ma raison d\'être, ce qui me permet de me lever avec l’envie de rendre le monde meilleur. Au Japon, le terme "Ikigai" fait référence à "sa propre raison d\'être". C’est ce que je veux partager avec vous par le biais d’IKI. »'
          )}
        </h3>
        <h3 className="bold uppercase">{t("Tan, fondateur d'IKI")}</h3>
      </div>
      <div className="references">
        <h2>{t("Ils parlent de nous")}</h2>
        <div className="references-scroll">
          <a
            href="https://www.brusselskitchen.com/iki/bruxelles/restaurant"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              src="../../images/brands/bk.webp"
              className="references-scroll-image"
              placeholder="blurred"
              objectFit="contain"
              alt="bk"
            />
          </a>
          <a
            href="https://www.elle.fr/Elle-a-Table"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              src="../../images/brands/elleatable.webp"
              className="references-scroll-image"
              placeholder="blurred"
              objectFit="contain"
              alt="elleatable"
            />
          </a>
          <a
            href="https://www.femmesdaujourdhui.be/cuisine/"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              src="../../images/brands/femmesdelice.webp"
              className="references-scroll-image"
              placeholder="blurred"
              objectFit="contain"
              alt="femmesdelice"
            />
          </a>
          <a
            href="https://www.flair.be/fr/chillax/food/on-a-teste-iki-cantine-eurasienne/"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              src="../../images/brands/flair.webp"
              className="references-scroll-image"
              placeholder="blurred"
              objectFit="contain"
              alt="flair"
            />
          </a>
          <a href="https://www.ichec.be/en" target="_blank" rel="noreferrer">
            <StaticImage
              src="../../images/brands/ichec.webp"
              className="references-scroll-image"
              placeholder="blurred"
              objectFit="contain"
              alt="ichec"
            />
          </a>
          <a href="https://potimanon.com/" target="_blank" rel="noreferrer">
            <StaticImage
              src="../../images/brands/potimanon.webp"
              className="references-scroll-image"
              placeholder="blurred"
              objectFit="contain"
              alt="potimanon"
            />
          </a>
          <a href="https://sosoir.lesoir.be/" target="_blank" rel="noreferrer">
            <StaticImage
              src="../../images/brands/sosoir.webp"
              className="references-scroll-image"
              placeholder="blurred"
              objectFit="contain"
              alt="sosoir"
            />
          </a>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
